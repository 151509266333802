import React, { useEffect, useState, useRef } from 'react';
import Header from './Header';
import Marquee from './Marquee';
import Hero from './Hero';
import Features from './Features';
import Advantage from './Advantage';
import Security from './Security';
import Mission from './Mission';
import HowItWorks from './HowItWorks';
import Benefits from './Benefits';
import ComingSoon from './ComingSoon';
import Footer from './Footer';

function App() {
  const emailInputRef = useRef(null);

  return (
    <div>
      <Header emailInputRef={emailInputRef} />
      <Marquee />
      <Hero/>
      <Features />
      <Advantage />
      <Security /> 
      <Mission />
      <HowItWorks />
      <Benefits />
      <ComingSoon />
      <Footer ref={emailInputRef} />
    </div>
  );
}

export default App;
