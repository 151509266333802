import Layout from './Layout';
import { Languages, Users2, Monitor } from 'lucide-react';


const Features = () => {
    return (
        <div className="p-6 my-6 bg-white">
            <Layout>
                {/* Heading Section */}
                <div className="text-center max-w-4xl mx-auto mb-16">
                    <h2 className="text-3xl md:text-4xl font-bold mb-6">
                        Innovative Concepts Tailored for the Gulf
                    </h2>
                    <p className="text-gray-700 text-lg">
                        At GulfGig, we understand the unique dynamics of the Gulf region. Our platform is built to cater to the specific needs of freelancers and businesses in this region, ensuring:
                    </p>
                </div>
                {/* Cards Container */}
                <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
                    {/* Multi-language Support Card */}
                    <div className="w-64 aspect-square bg-emerald-500 rounded-lg p-6 text-center text-white flex flex-col items-center justify-center">
                        <div className="mb-4">
                            <Languages className="w-10 h-10" />
                        </div>
                        <h3 className="text-lg font-semibold mb-3">
                            Multi-language Support
                        </h3>
                        <p className="text-sm">
                            Multi-language support for Arabic and English with right-to-left alignment for Arabic users.
                        </p>
                    </div>
                    {/* Advanced Job-matching Card */}
                    <div className="w-64 aspect-square bg-emerald-500 rounded-lg p-6 text-center text-white flex flex-col items-center justify-center">
                        <div className="mb-4">
                            <Users2 className="w-10 h-10" />
                        </div>
                        <h3 className="text-lg font-semibold mb-3">
                            Advanced Job-matching
                        </h3>
                        <p className="text-sm">
                            Advanced job-matching algorithms that connect the right talent with the right projects.
                        </p>
                    </div>
                    {/* User-friendly Interface Card */}
                    <div className="w-64 aspect-square bg-emerald-500 rounded-lg p-6 text-center text-white flex flex-col items-center justify-center">
                        <div className="mb-4">
                            <Monitor className="w-10 h-10" />
                        </div>
                        <h3 className="text-lg font-semibold mb-3">
                            User-friendly Interface
                        </h3>
                        <p className="text-sm">
                            A user-friendly interface that simplifies the hiring and freelancing process.
                        </p>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Features;