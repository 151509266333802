// src/Header.js
import React, { useState } from 'react';
// import { Globe } from 'lucide-react';
import Logo from './assets/img/logo-cropped-min.webp'
import Layout from './Layout';

const Header = ({ emailInputRef }) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const [selectedLanguage, setSelectedLanguage] = useState('English');

    // const toggleDropdown = () => {
    //     setIsOpen(!isOpen);
    // };

    // const handleLanguageChange = (language) => {
    //     setSelectedLanguage(language);
    //     setIsOpen(false);
    // };

    const scrollToFooter = () => {
        if (emailInputRef.current) {
            emailInputRef.current.scrollIntoView({ behavior: 'smooth' });
            emailInputRef.current.focus();
        }
    };

    return (
        <Layout>
        <header className="flex items-center justify-between py-2 bg-white border-b border-gray-200">
            {/* Logo */}
            <div className="flex items-center">
                <div className="text-3xl font-bold">
                    <img src={Logo} alt="GulfGig Logo" className="h-12" />
                </div>
            </div>

            {/* Right side controls */}
            <div className="flex items-center space-x-4">
                {/* Language Selector */}
                {/* <div className="relative">
                    <div
                        onClick={toggleDropdown}
                        className="flex items-center cursor-pointer rounded-lg p-[1px] bg-gradient-to-r from-primary-blue to-primary-teal"
                    >
                        <div className="flex items-center px-4 py-2 bg-white rounded-lg">
                            <Globe className="w-4 h-4 mr-2 text-gray-600" />
                            <span className="text-sm text-gray-700">{selectedLanguage}</span>
                            <svg
                                className="w-4 h-4 ml-2 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </div>
                    </div>
                    {/* {isOpen && (
                        <ul className="absolute right-0 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                            <li
                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleLanguageChange('English')}
                            >
                                English
                            </li>
                            <li
                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleLanguageChange('Spanish')}
                            >
                                Spanish
                            </li>
                        </ul>
                    )} 
                </div> */}

                {/* Sign Up Button */}
                <button
                    onClick={scrollToFooter}
                    className="px-7 py-2.5 text-white font-medium rounded-md bg-gradient-to-r from-primary-blue to-primary-teal hover:opacity-90 transition duration-300"
                >
                    SIGN UP
                </button>
            </div>
        </header>
        </Layout>
    );
};

export default Header;
