import Layout from './Layout';
import missionImage from './assets/img/mission-min.webp';

const Mission = () => {
    return (<Layout>
        <div className="my-12 p-6 bg-white">
            
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-primary-teal to-primary-blue" style={{lineHeight: "inherit"}}>
                        Bridging the Gap Between Freelancers and Clients
                    </h2>
                    <h3 className="text-2xl font-semibold text-gray-800 my-6">
                        Our mission is simple
                    </h3>
                    <p className="text-xl text-gray-700 mb-12">
                        To eliminate the hurdles that freelancers and clients face in finding each other. GulfGig connects skilled professionals with businesses, creating opportunities and fostering success on both sides.
                    </p>
                    <div className="rounded-2xl overflow-hidden shadow-lg">
                        <img 
                            src={missionImage} 
                            alt="Bridging the Gap" 
                            className="w-full h-auto"
                        />
                    </div>
                </div>
        </div>
        </Layout>

    );
};

export default Mission;