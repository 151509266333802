import React from 'react';

const Layout = ({ children }) => {
    return (
        <div className="max-w-7xl mx-auto px-6">
            {children}
        </div>
    );
};

export default Layout;