import Layout from './Layout';
import { DollarSign, UserCheck, Wrench } from 'lucide-react';

const Benefits = () => {
    return (
        <Layout>
        <div className="p-6 my-12 bg-white">
            
                <div className="mx-auto text-center">
                    <h2 className="text-4xl font-bold mb-12">
                        Mutual Benefits for Freelancers and Clients
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        <div className="flex flex-col items-center">
                            <div className="bg-[#2F3192] p-6 rounded-full mb-4">
                                <DollarSign className="text-white w-12 h-12" />
                            </div>
                            <p className="text-lg">
                                Freelancers gain access to high-paying gigs tailored to their expertise.
                            </p>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="bg-[#2F3192] p-6 rounded-full mb-4">
                                <UserCheck className="text-white w-12 h-12" />
                            </div>
                            <p className="text-lg">
                                Clients receive expert solutions without the overhead of full-time hiring.
                            </p>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="bg-[#2F3192] p-6 rounded-full mb-4">
                                <Wrench className="text-white w-12 h-12" />
                            </div>
                            <p className="text-lg">
                                Both parties enjoy a streamlined experience with GulfGig’s intuitive tools and features.
                            </p>
                        </div>
                    </div>
                </div>
            
        </div></Layout>
    );
};

export default Benefits;