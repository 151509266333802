import React, { forwardRef, useState } from 'react';
import Layout from './Layout';

function getCsrfToken() {
    const match = document.cookie.match(new RegExp('(^| )XSRF-TOKEN=([^;]+)'));
    if (match) return match[2];
    return null;
}

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

const Footer = forwardRef((props, ref) => {
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleInputChange = (e) => {
        if (isButtonDisabled) {
            setIsButtonDisabled(false);
            setMessage('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;

        if (!isValidEmail(email)) {
            setMessage('Please enter a valid email address.');
            setMessageType('error');
            return;
        }

        try {
            const response = await fetch('https://www.gulfgig.com/api/save-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCsrfToken(),
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                setMessage('Failed to save email. Please try again later.');
                setMessageType('error');
                return;
            }

            setMessage("Thanks for signing up!! You'll be the first to know about our launch.");
            setMessageType('success');
            setIsButtonDisabled(true);
        } catch (error) {
            console.error('Error saving email:', error);
            setMessage('Failed to save email. Please try again later.');
            setMessageType('error');
        }
    };

    return (
        <div className="my-12 bg-white">
            <Layout>
                <div className="text-center">
                    <h2 className="text-3xl font-bold mb-4">
                        Sign Up for Updates
                    </h2>
                    <p className="text-lg text-gray-800 mb-8">
                        Don't miss out! Subscribe to our newsletter for the latest news and updates about GulfGig's launch.
                    </p>

                    <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter your Email"
                            ref={ref}
                            className="w-full sm:w-80 px-4 py-3 rounded-lg border border-emerald-200 focus:outline-none focus:border-emerald-500"
                            onChange={handleInputChange}
                        />
                        <button
                            type="submit"
                            className={`w-full sm:w-auto px-8 py-3 text-white rounded-lg transition duration-300 ${isButtonDisabled 
                                        ? 'bg-gray-400 cursor-not-allowed' 
                                        : 'bg-emerald-500 hover:bg-emerald-600'
                            }`}
                            disabled={isButtonDisabled}
                        >
                            SIGN UP
                        </button>
                    </form>
                    <div className='my-4 h-6'>
                        <p className={`${messageType === 'success' ? 'text-green-600' : 'text-red-600'} leading-6`}>
                            {message}&nbsp;
                        </p>
                    </div>
                    <hr className="border-t border-gray-300 mb-4" />
                    <p className="text-sm text-gray-600">
                        © 2024 GulfGig.com All rights reserved.
                    </p>
                </div>
            </Layout>
        </div>
    );
});

export default Footer;