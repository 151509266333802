import Layout from './Layout';
import { Dot } from 'lucide-react';
import advantageImage from './assets/img/advantage.webp';

const Advantage = () => {
    return (
        <Layout>
        <div className="bg-[#2F3192] rounded-3xl my-12 p-6">
            
                <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center gap-12">
                    {/* Left Content */}
                    <div className="lg:w-1/2 text-white order-2 lg:order-1">
                        <h2 className="text-3xl font-bold mb-6">
                            The GulfGig Advantage
                        </h2>
                        <p className="text-xl mb-4">
                            What sets us apart from the rest?
                        </p>
                        <ul className="space-y-4">
                            <li className="flex gap-1 items-center">
                                <Dot className="w-12 h-12 flex-shrink-0 -ml-2" />
                                <div>
                                    <span className="text-lg">
                                        <b className="font-semibold">Custom Solutions:</b>
                                        {' '}Find the best talent for your projects, whether short-term gigs or long-term collaborations.
                                    </span>
                                </div>
                            </li>
                            <li className="flex gap-1 items-center">
                                <Dot className="w-12 h-12 flex-shrink-0 -ml-2" />
                                <div>
                                    <span className="text-lg">
                                        <b className="font-semibold">Milestone-Based Payments:</b>
                                        {' '}Secure and transparent payment processes for every project milestone.
                                    </span>
                                </div>
                            </li>
                            <li className="flex gap-1 items-center">
                                <Dot className="w-12 h-12 flex-shrink-0 -ml-2" />
                                <div>
                                    <span className="text-lg">
                                        <b className="font-semibold">Real-Time Communication:</b>
                                        {' '}Chat tools to streamline discussions between clients and freelancers.
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* Right Image */}
                    <div className="lg:w-1/2 order-1 lg:order-2">
                        <img 
                            src={advantageImage} 
                            alt="Team collaboration" 
                            className="rounded-2xl w-full h-full object-cover"
                        />
                    </div>
                </div>
            
        </div>
        </Layout>
    );
};

export default Advantage;