import Layout from './Layout';
import securityImage from './assets/img/security-min.webp'

const Security = () => {
    return (
        <Layout>
        <div className="bg-emerald-500 rounded-3xl my-12 p-6">
            
                <div className="flex flex-col lg:flex-row items-center gap-12">
                    {/* Left Image Section */}
                    <div className="lg:w-1/2">
                        <img 
                            src={securityImage} 
                            alt="Security Features" 
                            className="w-full h-full rounded-2xl"
                        />
                    </div>
                    {/* Right Content Section */}
                    <div className="lg:w-1/2 text-white">
                        <h2 className="text-3xl font-bold mb-6">
                            Your Security is Our Priority
                        </h2>
                        <p className="text-xl mb-4">
                            At GulfGig, we prioritize the safety and security of every user:
                        </p>
                        <ul className="space-y-4">
                            <li className="flex items-start gap-2">
                                <div className="w-2 h-2 rounded-full bg-white mt-2 flex-shrink-0"></div>
                                <span className="text-lg">
                                    All profiles are thoroughly verified to ensure authenticity.
                                </span>
                            </li>
                            <li className="flex items-start gap-2">
                                <div className="w-2 h-2 rounded-full bg-white mt-2 flex-shrink-0"></div>
                                <span className="text-lg">
                                    Advanced data encryption protects your personal and financial information.
                                </span>
                            </li>
                            <li className="flex items-start gap-2">
                                <div className="w-2 h-2 rounded-full bg-white mt-2 flex-shrink-0"></div>
                                <span className="text-lg">
                                    Contact details are securely managed to maintain confidentiality within the platform.
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            
        </div>
        </Layout>
    );
};

export default Security;