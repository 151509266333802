import React, { useState } from 'react';
import Layout from './Layout';
import Logo from './assets/img/logo-full-min.webp'
import HeroPattern from './assets/img/hero-pattern-min.webp'

function getCsrfToken() {
  const match = document.cookie.match(new RegExp('(^| )XSRF-TOKEN=([^;]+)'));
  if (match) return match[2];
  return null;
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const Hero = () => {
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleInputChange = (e) => {
        if (isButtonDisabled) {
            setIsButtonDisabled(false);
            setMessage('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;

        if (!isValidEmail(email)) {
            setMessage('Please enter a valid email address.');
            setMessageType('error');
            return;
        }

        try {
            const response = await fetch('https://www.gulfgig.com/api/save-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCsrfToken(),
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                setMessage('Failed to save email. Please try again later.');
                setMessageType('error');
                return;
            }

            setMessage("Thanks for signing up!! You'll be the first to know about our launch.");
            setMessageType('success');
            setIsButtonDisabled(true);
        } catch (error) {
            console.error('Error saving email:', error);
            setMessage('Failed to save email. Please try again later.');
            setMessageType('error');
        }
    };

    return (
        <div className="relative overflow-hidden py-4">
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `linear-gradient(to top right, rgba(	0,166,116, 0.15), rgba(	35,76,139, 0.15))`,
                    backgroundSize: 'cover',
                    zIndex: -1
                }}
            />
            <div
                className="absolute inset-0"
                style={{
                    backgroundImage: `url(${HeroPattern})`,
                    backgroundSize: 'cover',
                    opacity: 0.05,
                    zIndex: -1
                }}
                
            />
            <Layout>
                <div className="container mx-auto text-center">
                    {/* Logo */}
                    <div className="my-12">
                        <img 
                            src={Logo} 
                            alt="GulfGig Logo" 
                            className="max-h-40 w-auto mx-auto"
                        />
                    </div>
                    {/* Heading */}
                    <h1 className="text-6xl font-bold mb-6 tracking-wide">
                        Hey Gulfian's <span role="img" aria-label="wave">👋🏻</span>, Welcome to GulfGig
                    </h1>
                    {/* Description */}
                    <p className="text-gray-700 mx-auto mb-12 text-xl leading-relaxed">
                        GulfGig is the Gulf region's premier platform, designed to bring freelancers 
                        and clients together in a seamless, secure, and efficient way. <br/> Whether you're 
                        a skilled professional or a business in need of expert solutions, GulfGig is 
                        your gateway to opportunities.
                    </p>
                    {/* Sign up section */}
                    <div className="max-w-xl mx-auto">
                        <p className="text-gray-600 mb-4">
                            Sign up to be the first to get updates.
                        </p>
                        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 justify-center items-center">
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your Email"
                                onChange={handleInputChange}
                                className="w-full sm:w-80 px-4 py-3 rounded-lg border border-emerald-200 focus:outline-none focus:border-emerald-500"
                            />
                            <button
                                type="submit"
                                disabled={isButtonDisabled}
                                className={`w-full sm:w-auto px-8 py-3 rounded-lg transition duration-300 ${
                                    isButtonDisabled 
                                        ? 'bg-gray-400 cursor-not-allowed' 
                                        : 'bg-emerald-500 hover:bg-emerald-600'
                                } text-white`}
                            >
                                SIGN UP
                            </button>
                        </form>
                        <div className='mt-4 min-h-[24px]'>
                            {message && (
                                <p className={`${messageType === 'success' ? 'text-green-600' : 'text-red-600'}`}>
                                    {message}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Hero;
