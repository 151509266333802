import Layout from './Layout';
import freelancerImage1 from './assets/img/howitworks/1.webp';
import freelancerImage2 from './assets/img/howitworks/2.webp';
import freelancerImage3 from './assets/img/howitworks/3.webp';
import clientImage1 from './assets/img/howitworks/4.webp';
import clientImage2 from './assets/img/howitworks/5.webp';
import clientImage3 from './assets/img/howitworks/6.webp';

const HowItWorks = () => {
    return (
        <Layout>
        <div className="bg-gradient-to-r from-primary-blue to-primary-teal rounded-3xl p-6 my-12">
            
                <div className="mx-auto">
                    {/* Freelancers Section */}
                    <h2 className="text-3xl font-bold text-white mb-6">
                        How GulfGig Works for Freelancers
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-6">
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <img src={freelancerImage1} alt="Expand Opportunities" className="w-full h-40 object-cover mb-4 rounded" />
                            <h3 className="text-xl font-semibold mb-2">Expand Your Opportunities</h3>
                            <p>Showcase your skills, experience, and portfolio to clients in the Gulf region.</p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <img src={freelancerImage2} alt="Get Paid Securely" className="w-full h-40 object-cover mb-4 rounded" />
                            <h3 className="text-xl font-semibold mb-2">Get Paid Securely</h3>
                            <p>Earn money through safe and milestone-based payments.</p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <img src={freelancerImage3} alt="Enhance Your Reputation" className="w-full h-40 object-cover mb-4 rounded" />
                            <h3 className="text-xl font-semibold mb-2">Enhance Your Reputation</h3>
                            <p>Build a strong profile with ratings and reviews from clients.</p>
                        </div>
                    </div>
                    {/* Clients Section */}
                    <h2 className="text-3xl font-bold text-white my-6">
                        How GulfGig Works for Clients
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <img src={clientImage1} alt="Find Top Talent" className="w-full h-40 object-cover mb-4 rounded" />
                            <h3 className="text-xl font-semibold mb-2">Find Top Talent</h3>
                            <p>Post your job requirements and let GulfGig match you with the best freelancers.</p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <img src={clientImage2} alt="Manage Projects with Ease" className="w-full h-40 object-cover mb-4 rounded" />
                            <h3 className="text-xl font-semibold mb-2">Manage Projects with Ease</h3>
                            <p>Use our platform to set milestones, track progress, and communicate effectively.</p>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-6">
                            <img src={clientImage3} alt="Achieve Business Goals" className="w-full h-40 object-cover mb-4 rounded" />
                            <h3 className="text-xl font-semibold mb-2">Achieve Business Goals</h3>
                            <p>Access cost-effective solutions with talented professionals ready to deliver.</p>
                        </div>
                    </div>
                </div>
            
        </div></Layout>
    );
};

export default HowItWorks;