import Layout from './Layout';

const ComingSoon = () => {
    return (
        <Layout>
        <div className="p-6 my-12 bg-white text-center">
            
                <div className="max-w-6xl mx-auto">
                <h2 className="text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary-blue to-primary-teal p-2">
                Coming Soon to the Gulf Market
                    </h2>
                    <p className="text-2xl mt-10 text-gray-800">
                        We’re excited to announce that GulfGig is on the way! Stay tuned as we prepare to revolutionize how freelancers and businesses connect in the Gulf. Be among the first to experience the future of freelancing.
                    </p>
                </div>
        </div>
        </Layout>

    );
};

export default ComingSoon;