import React from 'react';
import Megaphone from './assets/img/megaphone-min.png';

const Marquee = () => {
    return (
        <div className="bg-gradient-to-r from-emerald-400 to-emerald-500 overflow-hidden relative">
            <div className="flex whitespace-nowrap">
                {/* First copy of the marquee */}
                <div className="animate-marquee inline-flex whitespace-nowrap py-2">
                    {[...Array(6)].map((_, index) => (
                        <span key={index} className="mx-4 inline-flex items-center text-primary-blue font-medium">
                            <img src={Megaphone} alt="Megaphone" className="w-5 h-5 mr-2" />
                            We are Launching Soon!
                        </span>
                    ))}
                </div>
                {/* Second copy of the marquee */}
                <div className="animate-marquee2 inline-flex whitespace-nowrap py-2 absolute top-0">
                    {[...Array(6)].map((_, index) => (
                        <span key={index} className="mx-4 inline-flex items-center text-primary-blue font-medium">
                            <img src={Megaphone} alt="Megaphone" className="w-5 h-5 mr-2" />
                            We are Launching Soon!
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Marquee;
